import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import styled from '@lib/styled';
import { Space, Typography, Divider, Anchor, Tag } from 'antd';
import Button from '@components/atoms/Button';
import Logo from '@icons/Logo';
import brand from '@lib/brand';
import firebase from '../../lib/firebase';
const { Text, Title } = Typography;
const { Link } = Anchor;

export default function LogoStack({ href = '/' }) {
  const router = useRouter();
  const crumbs = router.asPath.split('/');

  return (
    <a href={href}>
      <LogoSection>
        <Logo width={58} height={58} />
        <Stack>
          <CompactTitle level={2} style={{ margin: 0 }}>
            {brand.brand}
          </CompactTitle>
          <Tagline>{brand.tagline}</Tagline>
        </Stack>
        {/* <Divider type="vertical" style={{ height: 40 }} /> */}
        <TagText level={4}>{/* BETA */}</TagText>
      </LogoSection>
    </a>
  );
}

const LogoSection = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 230px;
`;

const Tagline = styled(Text)`
  margin-bottom: 5px;
`;

const TagText = styled(Title)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompactTitle = styled(Title)`
  margin-top: 0px;
  margin-bottom: 0px;
`;
